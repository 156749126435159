import React from 'react';
import s from './styles.module.scss';

const Footer = () => (
  <footer className={s.root}>
    <div className={s.copy}>©&nbsp;OTANI&nbsp;KOJI</div>
  </footer>
);

export default Footer;
