import React from 'react';
import IconCursor from '@/assets/icons/cursor.svg';
import s from './styles.module.scss';

const Loading = (props) => {
  return (
    <div className={s.root}>
      <IconCursor className={s.dot} />
      <div className={s.inner}>
        <div className={s.logo}>Otani Koji</div>
        <div className={s.spinner}><span className={s.spinnerInner} /></div>
      </div>
    </div>
  );
};

export default Loading;
