import React from "react";
import { graphql } from 'gatsby'
import SEO from "@/components/seo";
import NoSSR from '@/components/no-ssr';
import Layout from "@/components/layout";
import Map from '@/components/Map';

const PAGE = {
  pageTitle: 'KOJI OTANI | Main',
  sectionTitle: '...',
  pageDescription: '...',
  headerColor: 'white',
}

const MapPage = ({ data }) => {
  const cities = data.allCitiesJson.edges.map(e => e.node);
  // console.log('home page data', allAlbums);
  const markers = cities.map(e => ({
    title: e.name,
    path: `/gallery/${e.region.id}/${e.country.id}/${e.id}`,
    position: { lat: e.map.lat, lng: e.map.lng },
  }));
  // console.log('markers', markers);
  return (
    <Layout page={PAGE}>
      <SEO title={PAGE.pageTitle} />
      <NoSSR>
        <div style={{ position: 'fixed', height: '100%', width: '100%', top: 0, left: 0, }}>
          <Map markers={markers} />
        </div>
      </NoSSR>
    </Layout>
  );
};

export default MapPage;

export const query = graphql`
  query {
    allCitiesJson {
      edges {
        node {
          id
          name
          gallery
          region {
            id
            name
          }
          country {
            id
            name
          }
          map {
            lat
            lng
          }
        }
      }
    }
  }
`;