import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { navigate } from 'gatsby';
// import cx from 'classnames';
import GoogleMapReact from 'google-map-react';
import MapMarker from '@/components/MapMarker';
import mapStyles from './mapStyles.json';
import styles from './styles.module.scss';

const GOOGLE_API_KEY = 'AIzaSyC-62W5MCqjzIwEUM7kKAVRCGj_WaSXsL8';

class Map extends Component {
  state = {
    isMarkerShown: false,
    _isMounted: false,
  }

  componentDidMount() {
    this.delayedShowMarker()
  }

  delayedShowMarker = () => {
    // setTimeout(() => {
    //   this.setState({ isMarkerShown: true })
    // }, 3000)
  }

  handleMarkerClick = (key, childProps) => {
    console.log('navigate to the gallery page');
    navigate(childProps.path);
  }

  render() {
    const { markers } = this.props;

    return (
      <div className={styles.root}>
        <GoogleMapReact
          bootstrapURLKeys={{ key: GOOGLE_API_KEY }}
          defaultCenter={{ lat: markers[markers.length - 1].position.lat, lng: markers[markers.length - 1].position.lng }}
          defaultZoom={3}
          options={{
            disableDefaultUI: true,
            zoomControl: false,
            scaleControl: false,
            scrollwheel: false,
            styles: mapStyles,
          }}
          onChildClick={this.handleMarkerClick}
        >
          {markers.map((marker, i) => (
            <MapMarker
              key={i}
              lat={marker.position.lat}
              lng={marker.position.lng}
              text={marker.title}
              path={marker.path}
              size={marker.size}
              primary={(i+1) === markers.length}
              // onClick={this.handleMarkerClick}
            />
          ))}
        </GoogleMapReact>
      </div>
    );
  }
}

export default Map;

Map.propTypes = {
  markers: PropTypes.arrayOf(PropTypes.shape({
    position: PropTypes.shape({
      lat: PropTypes.number,
      lng: PropTypes.number
    }),
    title: PropTypes.string.isRequired,
    path: PropTypes.string.isRequired,
    size: PropTypes.oneOf(['small', 'medium' ,'large']),
  })),
}