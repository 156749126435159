import React, { Component } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import styles from './styles.module.scss';

class MapMarker extends Component {
  state = {
    hover: false,
    animExit: false
  }

  handleMouseOver() {
    if (!this.state.hover) {
      this.setState({ hover: true })
    }
  }

  handleMouseLeave() {
    this.setState({ hover: false });
    // setTimeout(() => {
    //   this.setState({
    //     hover: false,
    //     animExit: false
    //   });
    // }, 600);
  }

  render() {
    const { text, size, color, primary } = this.props;

    return (
      <div
        className={styles.markerWrap}
      >
        <div
          // className={cx(styles.pin, styles.bounce)}
          className={cx(styles.icon, styles[`icon_${size}`], primary && styles.iconPrimary)}
          style={{ backgroundColor: color, cursor: 'pointer' }}
          title={text}
          onMouseOver={this.handleMouseOver.bind(this)}
          onMouseLeave={this.handleMouseLeave.bind(this)}
        >{primary && <div className={cx(styles.blink, this.state.hover && styles.blinkPause)} />}</div>
        <div
          className={cx(
            styles.tooltip,
            this.state.hover && styles.tooltipIn ,
            // this.state.animExit && styles.tooltipExit
          )}
        >
          <div className={styles.title}>
            <div className={cx(
              styles.titleInner,
              this.state.hover && styles.titleInnerIn,
              // this.state.animExit & & styles.titleInnerExit,
            )}>{text}</div>
          </div>
        </div>
      </div>
    );
  }
};

export default MapMarker;

MapMarker.propTypes = {
  text: PropTypes.string.isRequired,
  size: PropTypes.oneOf(['small', 'medium' ,'large']),
  color: PropTypes.oneOf(['red', 'blue' ,'orange']), //todo: pallete
  primary: PropTypes.bool
};