import React from 'react';
import PropTypes from 'prop-types';
import { StaticQuery, graphql } from 'gatsby';
import IconCursor from '@/assets/icons/cursor.svg';
import Header from './Header/';
import Footer from './Footer/';
import Loading from './Loading/';
import '@/styles/main.scss';

const Layout = ({ children, page }) => {
  const [cursor, setCursor] = React.useState([0, 0]);

  return (
    <StaticQuery
      query={graphql`
        query SiteTitleQuery {
          site {
            siteMetadata {
              title
            }
          }
        }
      `}
      render={data => (
        <div
          onMouseMove={(e) => setCursor([e.clientX, e.clientY])}
        >
          <Loading />
          {page &&
            <>
              <Header
                siteTitle={data.site.siteMetadata.title}
                currentPageTitle={page.sectionTitle || page.pageTitle}
                color={page.headerColor}
              />
              <div id="content">
                <main>{children}</main>
              </div>
              <Footer />
            </>
          }
          <div
            className={'custom-cursor'}
            style={{
              transform: `matrix(1, 0, 0, 1, ${cursor[0]}, ${cursor[1]})`
            }}
          ><IconCursor /></div>
        </div>
      )}
    />
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired
};

export default Layout;
