import React from 'react';
import canUseDOM from 'can-use-dom';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { Link } from 'gatsby';
import AniLink from 'gatsby-plugin-transition-link/AniLink'
import s from './styles.module.scss';

export const Button = ({ children, className, onClick, ...rest }) => {
  return (
    <button
      className={cx(s.root, className)}
      onClick={onClick}
      {...rest}
    >
      {children}
    </button>
  )
}

export const ButtonAniLink = ({ hasDot, children, className, color, size, to, component, ...rest }) => {
  const [hover, setHover] = React.useState(false);
  const handleMouseEnter = function() {
    setHover(true);
    if (canUseDOM) {
      document.body.classList.add('has-hovered-link');
    }
  };
  const handleMouseLeave = function() {
    setHover(false);
    if (canUseDOM) {
      document.body.classList.remove('has-hovered-link');
    }
  };

  return (
    <AniLink
      paintDrip
      hex="#ffbc00"
      className={cx(
        s.link,
        hover && '_hover',
        s[`link_${color}`],
        s[`link_${size}`],
        s[`link_${hasDot && 'hasDot'}`],
        className
      )}
      to={to}
      activeClassName={'_active-link'}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      {...rest}
    >
      {hasDot ? (
        <React.Fragment>
          <span className={s.dot} />
          <span className={cx(s.linkText, hover && '_hover')}>
            <span className={'H4'}>{children}</span>
          </span>
        </React.Fragment>
        ) : (
          <span className={'H4'}>{children}</span>
        )
      }
    </AniLink>
  )
}

export const ButtonLink = ({ hasDot, children, className, color, size, to, component, ...rest }) => {
  const [hover, setHover] = React.useState(false);
  const handleMouseEnter = function() {
    setHover(true);
    if (canUseDOM) {
      document.body.classList.add('has-hovered-link');
    }
  };
  const handleMouseLeave = function() {
    setHover(false);
    if (canUseDOM) {
      document.body.classList.remove('has-hovered-link');
    }
  };

  return (
    <Link
      className={cx(
        s.link,
        hover && '_hover',
        s[`link_${color}`],
        s[`link_${size}`],
        s[`link_${hasDot && 'hasDot'}`],
        className
      )}
      to={to}
      activeClassName={'_active-link'}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      {...rest}
    >
      {hasDot ? (
        <React.Fragment>
          <span className={s.dot} />
          <span className={cx(s.linkText, hover && '_hover')}>
            <span className={'H4'}>{children}</span>
          </span>
        </React.Fragment>
        ) : (
          <span className={'H4'}>{children}</span>
        )
      }
    </Link>
  )
}

export const ButtonA = ({ hasDot, children, className, href, target, color, size, ...rest }) => {
  const [hover, setHover] = React.useState(false);
  const handleMouseEnter = function() {
    setHover(true);
    document.body.classList.add('has-hovered-link');
  };
  const handleMouseLeave = function() {
    setHover(false);
    document.body.classList.remove('has-hovered-link');
  };

  return (
    <a
      className={cx(
        s.link,
        hover && '_hover',
        s[`link_${color}`],
        s[`link_${size}`],
        s[`link_${hasDot && 'hasDot'}`],
        className
      )}
      href={href}
      target={target}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      {...rest}
    >
      {hasDot ? (
        <React.Fragment>
          <span className={s.dot} />
          <span className={cx(s.linkText, hover && '_hover')}>
            <span className={'H4'}>{children}</span>
          </span>
        </React.Fragment>
        ) : (
          <span className={'H4'}>{children}</span>
        )
      }
    </a>
  )
}

Button.propTypes = {
  onClick: PropTypes.func
};

export const StyledButton = ({ children, primary, md, noBorder, className, onClick, ...rest }) => {
  return (
    <button
      className={
        cx(
          s.button, primary ? s.primary : '',
          md ? s.medium : '',
          noBorder ? s.noBorder : '',
          className
        )
      }
      onClick={onClick}
      {...rest}
    >
      {children}
    </button>
  )
}

StyledButton.propTypes = {
  onClick: PropTypes.func
};

export const StyledButtonSmall = ({ children, primary, className, onClick, ...rest }) => {
  return (
    <button className={cx(s.buttonSmall, primary ? s.primary : '', className)} onClick={onClick} {...rest}>
      {children}
    </button>
  )
}

StyledButton.propTypes = {
  onClick: PropTypes.func
};
