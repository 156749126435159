import React from 'react';
import { Link } from 'gatsby';
import cx from 'classnames';
import { Transition } from 'react-transition-group';
// import Logo from '@/assets/icons/logo.svg';
import IconFacebook from '@/assets/icons/facebook.svg';
import IconInstagram from '@/assets/icons/instagram.svg';
import IconMail from '@/assets/icons/envelope.svg';
import { ButtonLink, ButtonA, ButtonAniLink } from '@/components/UI/Button';
import s from './styles.module.scss';

const defaultStyle = {
  transition: `opacity ${300}ms ease-in-out`,
  opacity: 0,
  visibility: 'hidden',
}

const transitionStyles = {
  entering: { opacity: 1, visibility: 'visible' },
  entered:  { opacity: 1, visibility: 'visible' },
  exiting:  { opacity: 0, visibility: 'visible' },
  exited:  { opacity: 0, visibility: 'hidden' },
};

const socials=[
  {
    name: 'email',
    id: 'email',
    icon: <IconMail />,
    href: 'mailto:otani.travelphotography@gmail.com',
  },
  {
    name: 'facebook',
    id: 'facebook',
    icon: <IconFacebook />,
    href: 'https://www.facebook.com/KOJI-PHOTo-101430347982975/'
  },
  {
    name: 'instagram',
    id: 'instagram',
    icon: <IconInstagram />,
    href: 'https://www.instagram.com/otani_k'
  },
];

const nav = [
  {
    to: '/',
    title: 'Main',
  },
  {
    to: '/map',
    title: 'Travel Map',
  },
  {
    to: '/gallery/asia',
    title: 'Gallery',
    subNav: [
      {
        to: '/gallery/asia',
        title: 'Asia',
      }
    ]
  },
  {
    to: '/about',
    title: 'About Me',
  },
];

const NavLink = ({ title, to, subNav }) => {
  if(!subNav) {
    return (
      <ButtonLink
        to={to}
        className={s.mobileNavLink}
        activeClassName={s.mobileNavLinkActive}
        color={'black'}
        size={'large'}
      >
        <span className={'active-line'} />
        <span className={'active-text'}>{title}</span>
      </ButtonLink>
    );
  } else {
    return (
      <ButtonLink
        to={to}
        className={s.mobileNavLink}
        activeClassName={s.mobileNavLinkActive}
        color={'black'}
        size={'large'}
      >
        <span className={'active-line'} />
        <span className={'active-text'}>{title}</span>
      </ButtonLink>
    );
  }
}

const Header = ({ color }) => {
  const [open, setOpen] = React.useState();
  
  return (
    <header className={cx(s.root)}>
      <div className={s.container}>
        <div className={s.inner}>
          <ButtonAniLink to={'/'} className={s.logo}>Otani Koji</ButtonAniLink>
          <div className={cx(s.burger, open && s.burgerOpen)} onClick={() => setOpen(!open)}>
            <div className={cx(s.burgerLine, 'burgerLine')} />
            <div className={cx(s.burgerLine, 'burgerLine')} />
            <div className={cx(s.burgerLine, 'burgerLine')} />
          </div>
          <div className={s.nav}>
            {nav.map((v, i) => (
              <ButtonLink
                key={i}
                to={v.to}
                className={s.navLink}
                color={'black'}
                size={'small'}
                hasDot
              >{v.title}</ButtonLink>
            ))}
          </div>
          <div className={s.social}>
            {false && socials.map((v, i) => (
              <a
                key={i}
                href={v.href}
                className={s.socialLink}
                target="_blank"
                rel="noopener noreferrer"
              >{v.icon}</a>
            ))}
          </div>
        </div>
      </div>
      <Transition in={open} timeout={300}>
        {state => (
          <div style={{
            ...defaultStyle,
            ...transitionStyles[state]
          }}>
            <div className={s.mobileNav}>
              {nav.map((v, i) => (
                <NavLink
                  key={i}
                  {...v}
                />
              ))}

              <div className={s.mobileSocial}>
                {socials.map((v, i) => (
                  <ButtonA
                    key={i}
                    href={v.href}
                    className={s.mobileSocialLink}
                    color={'black'}
                    size={'small'}
                    target="_blank"
                    rel="noopener noreferrer"
                  >{v.name}</ButtonA>
                ))}
              </div>
            </div>
          </div>
        )}
      </Transition>
    </header>
  );
};

export default Header;
